
/* 富文本编辑器 修改高度*/
.editor {
	line-height: normal !important;
	margin-bottom: 0;

}

.editor ::v-deep .ql-container {
	min-height: 200px !important;
	max-height: 600px !important;
	overflow: auto;
	font-size: 20px;
	color: black;
}
