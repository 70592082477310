
.principle_size2 {
	p {
		text-indent: 0 !important;
	}
}

.principle_size {
	margin-top: 0 !important;

	p {
		font-size: 18px !important;
		text-indent: 30px !important;
		margin-bottom: 0px !important;
	}
}

.margin_select {
	margin: 0 10px;
}

// .delete_right{
//   display: flex;
//   position: relative;
//   width: 100%;
//   .v-btn{
//     position: absolute;
//     right: 0;
//     margin-top: 0px!important;
//   }
// }
.Triz_Select_fujia {
	line-height: 46px !important;

	.v-select__selection {
		text-indent: 10px;
	}
}
