
.Standard_solution {
	p {
		text-indent: 1em;
	}
}

.solution_problem {
	width: 800px;
	margin-left: 30px;

	p {
		text-indent: 1em;
	}
}

.solution_select {
	position: relative;
	top: 8px;
}

.solution_selectjian {
	position: relative;
	top: 6px;
}

.WTSolution {
	.tinymceClass {
		margin-left: 30px;
		text-indent: 1em;
	}

	.mce-content-body {
		width: 800px;
	}
}
