
.border {
  position: relative;
  border: 1px solid #888;
  border-radius: 4px;
  cursor: pointer;
}

.ma-2 {
  margin: 0 !important;
}

.title {
  position: absolute;
  top: 10px;
  width: 100%;
  text-align: center;
  font-size: 16px !important;
  color: #777;
  font-weight: 500;
}

.title_img {
  position: absolute;
  top: -12px;
  left: 5px;
  background: #fff;
  font-size: 12px !important;
  color: #777;
  font-weight: 500;
}

.close {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  border-radius: 25px;
}
